@use '@angular/material' as mat;
@use 'palette-comlink' as comlink;

@include mat.core();

$comlink-app-primary: mat.m2-define-palette(comlink.$primary-palette);
$comlink-app-accent: mat.m2-define-palette(
  comlink.$secondary-palette,
  A200,
  A100,
  A400
);

$comlink-app-warn: mat.m2-define-palette(mat.$m2-red-palette);

$comlink-app-theme: mat.m2-define-light-theme(
  (
    color: (
      primary: $comlink-app-primary,
      accent: $comlink-app-accent,
      warn: $comlink-app-warn,
    ),
    typography: mat.m2-define-typography-config(),
    density: 0,
  )
);

@include mat.all-component-themes($comlink-app-theme);

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-size: 14px;

  @font-face {
    font-family: 'Century Gothic';
    src: url('/assets/fonts/07558_CenturyGothic.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }

  body {
    font-size: 16px;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    --primary-color: #072b44;
    --secondary-color: #5cade7;
    --ternary-color: #cc8d30;
    --success-color: #74cd6e;
    --danger-color: #ed4b5a;
    --gray-color: #d8d8d8;
    --light-gray-color: #f5f5f5;
    --black-color: #000000;
    --white-color: #ffffff;
    --primary-hover: #1f6aa1;
    --primary-active: #0e1a21;
    --secondary-hover: #5cade7;
    --secondary-active: #98d5ff;
    --ternary-hover: #cc8d30;
    --ternary-active: #ffba52;
    --info-hover: #2879e3;
    --info-active: #2571d3;
    --danger-hover: #c72c3a;
    --danger-active: #ed4b5a;
    --success-hover: #3e8e41;
    --success-active: #74cd6e;
    --link: #1151ef;
    font-family: 'Century Gothic', fallback, sans-serif;
  }
}

.link {
  color: var(--link);
  cursor: pointer;
}

a {
  text-decoration: none;
  color: black;
}

.test {
  background-color: red;
}

.no-scroll {
  overflow: hidden;
}

.text-center {
  text-align: center;
}

.mb-10 {
  margin-bottom: 10px;
}

.mb-20 {
  margin-bottom: 20px;
}

.mt-10 {
  margin-top: 10px;
}

.mt-20 {
  margin-top: 20px;
}

.d-flex {
  display: flex;
}

.justify-evenly {
  justify-content: space-evenly;
}

.fz-12 {
  font-size: 12px;
}

.fz-14 {
  font-size: 14px;
}

.separator {
  width: 93%;
  height: 1px;
  background-color: var(--gray-color);
  margin: 16px auto;
}

.create-form {
  width: 100%;
  max-width: 600px;
  margin: 14px auto;
  display: flex;
  flex-direction: column;
  gap: 10px;

  * {
    font-size: 14px;
  }

  .civilite {
    display: flex;
    gap: 10px;
    align-items: center;
  }

  .form-result {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin: 10px 0;

    p {
      display: flex;
      flex-direction: column;
      gap: 10px;
      color: grey;
    }
  }

  .form-group {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 5px;
    @media (max-width: 900px) {
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
    }

    & > * {
      width: 100%;
    }
  }

  .form-group-textarea {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 5px;
    @media (max-width: 900px) {
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
    }

    & > * {
      width: 100%;
    }
  }

  textarea {
    height: 100px;
    text-indent: 0.4rem;
    border: 1px solid #ced4da;
    box-shadow: 0 2px 3px rgba(0, 0, 0, 0.1);
    -moz-appearance: textfield;
    padding: 5px 0;

    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    &:focus {
      outline-color: black;
    }
  }
}

.color-grey {
  color: grey;
}

.required::after {
  content: '*';
  color: red;
}

// classe pour les boutons sweetalert
.custom-confirm-button {
  background-color: var(--success-active) !important;
  width: 120px;
  border-radius: 20px !important;

  &:hover {
    background-color: var(--success-hover);
  }

  @media (max-width: 375px) {
    width: 100px;
  }
}

.custom-cancel-button {
  background-color: var(--danger-active) !important;
  width: 120px;
  border-radius: 20px !important;

  &:hover {
    background-color: var(--danger-hover);
  }

  @media (max-width: 375px) {
    width: 100px;
  }
}

.grey-button {
  background-color: grey !important;
  color: white;
  width: 120px;
  border-radius: 20px !important;
  @media (max-width: 375px) {
    width: 100px;
  }
}

.under-lined-h1 {
  font-size: 2em;
  text-align: left;
  margin-bottom: 16px;
  position: relative;
  display: inline-block;

  @media (max-width: 768px) {
    font-size: 1.2em;
  }
}

.under-lined-h1::before {
  content: '';
  display: block;
  width: 0; /* On commence à 0 pour l'animation */
  height: 0; /* On utilise une bordure pour le trait */
  border-bottom: 5px solid var(--primary-color); /* Épaisseur du trait et couleur */
  border-bottom-left-radius: 5px; /* Effet d'affinement */
  position: absolute;
  top: 100%; /* Placer le trait sous le texte */
  left: 0;
  transform-origin: left;
  transform: scaleX(0); /* Commence avec le trait affiné */
  animation: drawLine 1s ease-out forwards; /* Animation */
}

@keyframes drawLine {
  0% {
    width: 0;
    transform: scaleX(0.3); /* Début plus fin */
  }
  100% {
    width: 33.33%; /* 1/3 de la largeur du titre */
    transform: scaleX(1); /* Fin avec largeur normale */
  }
}

.nav-item {
  margin-right: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1 0;

  .icon-label {
    font-size: 10px;
    text-align: center;
  }

  &:last-child {
    margin-right: 0;
  }
}

.bottom-nav-container {
  display: flex;
  justify-content: space-around;
}
